import { ParseResult } from "papaparse";

export interface ChromeDataPassword {
  name?: string;
  url?: string;
  username?: string;
  password?: string;
  note?: string;
}

const processChromeData = (results: ParseResult<ChromeDataPassword>) => {
  return results.data.flatMap((result) => {
    if (!result.name || !result.password || !result.username) {
      return [];
    }
    return {
      title: result.name,
      description: result.note,
      username: result.username,
      password: result.password,
      url: result.url,
      isInstantShare: true,
      mustSendEmail: false,
      isLoading: false,
      isSuccess: false,
      isError: false,
    };
  });
};

export default processChromeData;
