import { useCallback, useState } from "react";

export interface DataPassword {
  title: string;
  description?: string;
  username: string;
  password: string;
  url?: string;
  isInstantShare: boolean;
  mustSendEmail: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
}

const useDataPasswords = () => {
  const [dataPasswords, setDataPasswords] = useState<DataPassword[]>([]);
  const removeDataPasswordElement = useCallback(
    (indexToDelete: number) =>
      setDataPasswords((oldDataPasswords) =>
        oldDataPasswords.filter((_, index) => index !== indexToDelete)
      ),
    [setDataPasswords]
  );
  const updateDataPasswordElement = useCallback(
    ({
      indexToUpdate,
      isLoading,
      isSuccess,
      isError,
    }: {
      indexToUpdate: number;
      isLoading: boolean;
      isSuccess: boolean;
      isError: boolean;
    }) => {
      setDataPasswords((oldDataPasswords) => {
        const newDataPasswords = [...oldDataPasswords];
        newDataPasswords[indexToUpdate] = {
          ...newDataPasswords[indexToUpdate],
          isLoading,
          isSuccess,
          isError,
        };
        return newDataPasswords;
      });
    },
    [setDataPasswords]
  );
  return {
    dataPasswords,
    setDataPasswords,
    removeDataPasswordElement,
    updateDataPasswordElement,
  };
};

export default useDataPasswords;
