import { useState } from "react";

import { SnackBarAlertInterface } from "@bones-app/react-snack-bar-alert";

import useSendDataToBackend from "./container/sendDataToBackend";
import useDataPasswords from "./container/useDataPasswords";
import useLoadFile from "./container/useLoadFile";
import ImportDataPasswordView from "./view";

export type Browser = "bitwarden" | "chrome" | "";
const ImportDataPassword = ({
  isEditing,
  setIsEditing,
  addSnackBarAlert,
}: {
  isEditing: boolean;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
  addSnackBarAlert: (snackBarAlert: SnackBarAlertInterface) => void;
}) => {
  const [browser, setBrowser] = useState<Browser>("");
  const {
    dataPasswords,
    setDataPasswords,
    removeDataPasswordElement,
    updateDataPasswordElement,
  } = useDataPasswords();
  const { onDrop, errorMessage, isError } = useLoadFile(
    browser,
    setDataPasswords
  );
  const { sendDataToBackend, isLoading } = useSendDataToBackend(
    addSnackBarAlert,
    updateDataPasswordElement
  );
  return (
    <ImportDataPasswordView
      {...{
        browser,
        setBrowser,
        onDrop,
        dataPasswords,
        removeDataPasswordElement,
        errorMessage,
        isError,
        isEditing,
        setIsEditing,
        sendDataToBackend,
        isLoading,
      }}
    />
  );
};

export default ImportDataPassword;
