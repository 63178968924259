import Dropzone, { Accept, FileRejection } from "react-dropzone";

import { Box, Typography, useTheme } from "@mui/material";

const DropZoneMaterialUIView = ({
  onDrop,
  accept,
  maxSize,
  isError,
  errorMessage,
}: {
  onDrop: (acceptedFiles: File[], fileRejections: FileRejection[]) => void;
  accept?: Accept;
  maxSize?: number;
  isError: boolean;
  errorMessage?: string;
}) => {
  const { palette } = useTheme();
  return (
    <Dropzone
      {...{
        multiple: false,
        maxFiles: 1,
        noDragEventsBubbling: true,
        maxSize,
        onDrop,
        accept,
      }}
    >
      {({ getRootProps, getInputProps }) => (
        <Box
          component="div"
          {...getRootProps()}
          sx={{
            borderWidth: 2,
            borderRadius: 4,
            borderColor: isError ? palette.error.main : palette.primary.main,
            borderStyle: "dashed",
            backgroundColor: palette.background.default,
            color: isError ? palette.error.main : palette.text.secondary,
            cursor: "pointer",
            outline: "none",
            padding: 3,
            textAlign: "center",
            transition: "border .24s ease-in-out",
            "&:hover": {
              borderColor: isError ? palette.error.main : palette.primary.dark,
            },
          }}
        >
          <input {...getInputProps()} />
          <Typography variant="body1">
            Faites glisser et déposez des fichiers ici ou cliquez pour
            parcourir.
          </Typography>
          {isError && (
            <Typography variant="body1" color="error" paddingTop={5}>
              <strong>{errorMessage}</strong>
            </Typography>
          )}
        </Box>
      )}
    </Dropzone>
  );
};

export default DropZoneMaterialUIView;
