import "dayjs/locale/fr";

import { lazy, Suspense, useMemo } from "react";
import { Route, Routes } from "react-router-dom";

import {
  AdminProtectedRoute,
  AntiProtectedRoute,
  BeneficiaryProtectedRoute,
  DonorProtectedRoute,
  ProtectedRoute,
  useIsDonorBeneficiaryAdmin,
} from "@bones-app/react-app-coffre-fort";
import { Loading } from "@bones-app/react-component-coffre-fort";
import { Footer, NavMenu } from "@bones-app/react-nav-menu";
import { Container } from "@mui/material";

import RightNav from "./RightNav";

const MentionsLegales = lazy(() =>
  import("@bones-app/react-nav-menu").then((module) => {
    return { default: module.MentionsLegales };
  })
);
const PrivacyPolicy = lazy(() =>
  import("@bones-app/react-nav-menu").then((module) => {
    return { default: module.PrivacyPolicy };
  })
);
const Admin = lazy(() => import("../views/Admin"));
const RegisterDonor = lazy(() => import("../views/user/RegisterDonor"));
const SetPassword = lazy(() => import("../views/user/SetPassword"));
const ForgottenPassword = lazy(() => import("../views/user/ForgottenPassword"));
const Login = lazy(() => import("./LoginCustom"));
const Dashboard = lazy(() => import("../views/Dashboard"));
const BeneficiaryList = lazy(() => import("../views/user/BeneficiaryList"));
const DataDonorList = lazy(() => import("../views/data/DataDonorList"));
const FAQ = lazy(() => import("../views/FAQ"));
const DataBeneficiaryList = lazy(
  () => import("../views/data/DataBeneficiaryList")
);

const commonPageList = [
  { link: { pathname: "/foires_aux_reponses" }, label: "Foire aux réponses" },
];
const donorPageList = [
  { link: { pathname: "/beneficiary" }, label: "Mes bénéficiaires" },
  { link: { pathname: "/jardin_secret" }, label: "Mon jardin secret" },
];
const beneficiaryPageList = [
  { link: { pathname: "/donnees_recues" }, label: "Mes données reçues" },
];

const MainApp = () => {
  const { isDonor, isBeneficiary } = useIsDonorBeneficiaryAdmin();
  const pageList = useMemo(() => {
    if (isDonor && isBeneficiary) {
      return [...donorPageList, ...beneficiaryPageList, ...commonPageList];
    } else if (isDonor) {
      return [...donorPageList, ...commonPageList];
    } else if (isBeneficiary) {
      return [...beneficiaryPageList, ...commonPageList];
    } else {
      return [];
    }
  }, [isBeneficiary, isDonor]);
  return (
    <>
      <NavMenu pageList={pageList} RightNav={RightNav} />
      <Container
        maxWidth={false}
        component="main"
        sx={{ paddingY: 2 }}
        disableGutters
      >
        <Suspense fallback={<Loading />}>
          <Routes>
            <Route path="mentions_legales" element={<MentionsLegales />} />
            <Route path="privacy" element={<PrivacyPolicy />} />
            <Route element={<AdminProtectedRoute />}>
              <Route path="/admin/dashboard" element={<Admin />} />
            </Route>

            <Route element={<ProtectedRoute />}>
              <Route path="*" element={<Dashboard />} />
              <Route path="/foires_aux_reponses" element={<FAQ />} />
            </Route>
            <Route element={<DonorProtectedRoute />}>
              <Route path="/beneficiary" element={<BeneficiaryList />} />
              <Route
                path="/jardin_secret/:tabIndexSelected?"
                element={<DataDonorList />}
              />
            </Route>
            <Route element={<BeneficiaryProtectedRoute />}>
              <Route
                path="/donnees_recues/:tabIndexSelected?"
                element={<DataBeneficiaryList />}
              />
            </Route>
            <Route element={<AntiProtectedRoute />}>
              <Route path="register" element={<RegisterDonor />} />
              <Route path="mdp_oublie" element={<ForgottenPassword />} />
              <Route path="password/:token" element={<SetPassword />} />
              <Route path="/login" element={<Login />} />
            </Route>
          </Routes>
        </Suspense>
      </Container>
      <Footer pageList={pageList} />
    </>
  );
};

export default MainApp;
