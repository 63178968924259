import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import QRCode from "react-qr-code";

import { Button, CircularProgress, Grid, Link } from "@mui/material";

import DialogForm from "../../../../components/DialogForm";
import { UpdateMfaFormInterface, updateMfaSchema } from "./container";

const UpdateMfaView = ({
  optUri,
  onSubmit,
  isLoading,
  isEditing,
  setIsEditing,
}: {
  optUri?: string;
  onSubmit: (values: UpdateMfaFormInterface) => Promise<void>;
  isLoading: boolean;
  isEditing: boolean;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const formView = <UpdateMfaFormView {...{ optUri, onSubmit, isLoading }} />;
  const description = !optUri ? (
    <>
      Téléchargez sur votre smartphone une application d'authentification, par
      exemple Google Authenticator (
      <Link
        href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
        color="inherit"
        target="_blank"
        rel="noopener"
      >
        Android
      </Link>{" "}
      &{" "}
      <Link
        href="https://apps.apple.com/us/app/google-authenticator/id388497605"
        color="inherit"
        target="_blank"
        rel="noopener"
      >
        iOS
      </Link>
      ).
      <p>
        <strong>
          ATTENTION : le QR code ne peut s'afficher qu'une seule et unique fois
          à l'écran suivant. Ayez votre Authenticator prêt à scanner
          {" :)"}
        </strong>
      </p>
    </>
  ) : (
    "Scannez ce QR code avec l'application d'authentification choisie afin de l'appairer avec Angelia."
  );
  return (
    <DialogForm
      {...{
        title: "Activation de la double authentification",
        description,
        formView,
        isLoading,
        isEditing,
        setIsEditing,
      }}
    />
  );
};

const UpdateMfaFormView = ({
  optUri,
  onSubmit,
  isLoading,
}: {
  optUri?: string;
  onSubmit: (values: UpdateMfaFormInterface) => Promise<void>;
  isLoading: boolean;
}) => {
  return (
    <Formik
      initialValues={{
        password: "",
      }}
      validationSchema={updateMfaSchema}
      onSubmit={onSubmit}
    >
      {() => (
        <Form>
          <Grid
            container
            direction="row"
            justifyContent="space-evenly"
            alignItems="stretch"
            spacing={3}
            sx={{ paddingX: 3, paddingY: 5 }}
          >
            {!optUri ? (
              <>
                <Grid item xs={12}>
                  <Field
                    component={TextField}
                    label="Retapez votre mot de passe Angelia"
                    name="password"
                    type="password"
                    autoComplete="password"
                    required={
                      updateMfaSchema.fields.password.spec.presence ===
                      "required"
                    }
                    fullWidth
                  />
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    type="submit"
                    disabled={isLoading}
                  >
                    Activer l'authentification à 2 facteurs
                    {isLoading && <CircularProgress color="secondary" />}
                  </Button>
                </Grid>
              </>
            ) : (
              <Grid item>
                <QRCode {...{ value: optUri, level: "H" }} />
              </Grid>
            )}
          </Grid>
        </Form>
      )}
    </Formik>
  );
};
export default UpdateMfaView;
