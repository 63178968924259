import { FileRejection } from "react-dropzone";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import ErrorIcon from "@mui/icons-material/Error";
import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";

import DialogForm from "../../../../components/DialogForm";
import DropZoneMaterialUI from "../../../../components/DropZoneMaterialUI";
import { Browser } from "./";
import { DataPassword } from "./container/useDataPasswords";

const UpdatePasswordView = ({
  browser,
  setBrowser,
  dataPasswords,
  removeDataPasswordElement,
  sendDataToBackend,
  isLoading,
  onDrop,
  errorMessage,
  isError,
  isEditing,
  setIsEditing,
}: {
  browser: Browser;
  setBrowser: React.Dispatch<React.SetStateAction<Browser>>;
  dataPasswords: DataPassword[];
  removeDataPasswordElement: (indexToDelete: number) => void;
  sendDataToBackend: (values: DataPassword[]) => Promise<void>;
  onDrop: (acceptedFiles: File[], fileRejections: FileRejection[]) => void;
  errorMessage?: string;
  isError: boolean;
  isLoading: boolean;
  isEditing: boolean;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const formView = (
    <UpdatePasswordFormView
      {...{
        browser,
        setBrowser,
        dataPasswords,
        removeDataPasswordElement,
        sendDataToBackend,
        isLoading,
        onDrop,
        errorMessage,
        isError,
      }}
    />
  );
  return (
    <DialogForm
      {...{
        title: `Importation des mots de passe depuis d'autres gestionnaires`,
        description:
          browser === "bitwarden" ? (
            <BitwardenMessage />
          ) : browser === "chrome" ? (
            <ChromeMessage />
          ) : (
            ""
          ),
        formView,
        isLoading,
        isEditing,
        setIsEditing,
      }}
    />
  );
};

const UpdatePasswordFormView = ({
  browser,
  setBrowser,
  dataPasswords,
  removeDataPasswordElement,
  sendDataToBackend,
  isLoading,
  onDrop,
  errorMessage,
  isError,
}: {
  browser: Browser;
  setBrowser: React.Dispatch<React.SetStateAction<Browser>>;
  dataPasswords: DataPassword[];
  removeDataPasswordElement: (indexToDelete: number) => void;
  sendDataToBackend: (values: DataPassword[]) => Promise<void>;
  onDrop: (acceptedFiles: File[], fileRejections: FileRejection[]) => void;
  errorMessage?: string;
  isError: boolean;
  isLoading: boolean;
}) => {
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-evenly"
      alignItems="stretch"
      spacing={3}
      sx={{ paddingX: 3, paddingY: 5 }}
    >
      {dataPasswords.length === 0 ? (
        <>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="navigateur-label">Gestionnaire</InputLabel>
              <Select
                labelId="navigateur-label"
                label="Gestionnaire"
                value={browser}
                onChange={(event) => setBrowser(event.target.value as Browser)}
              >
                <MenuItem value="bitwarden">Bitwarden</MenuItem>
                <MenuItem value="chrome">Chrome</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          {browser && (
            <Grid item xs={12}>
              <DropZoneMaterialUI
                {...{
                  accept: {
                    "text/csv": [".csv"],
                  },
                  onDrop,
                  isError,
                  errorMessage,
                }}
              />
            </Grid>
          )}
        </>
      ) : (
        <>
          <Grid item xs={12}>
            <List sx={{ backgroundColor: "#f3F3F3" }}>
              {dataPasswords.map((dataPassword, index) => (
                <ListItem
                  divider={index < dataPasswords.length - 1}
                  secondaryAction={
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      disabled={
                        dataPassword.isLoading ||
                        dataPassword.isSuccess ||
                        dataPassword.isError
                      }
                      onClick={() => removeDataPasswordElement(index)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  }
                  key={index}
                  sx={{ color: "#000000" }}
                >
                  {dataPassword.isLoading && (
                    <ListItemIcon>
                      <CircularProgress size={"2rem"} />
                    </ListItemIcon>
                  )}
                  {dataPassword.isSuccess && (
                    <ListItemIcon>
                      <CheckCircleIcon />
                    </ListItemIcon>
                  )}
                  {dataPassword.isError && (
                    <ListItemIcon>
                      <ErrorIcon color="error" />
                    </ListItemIcon>
                  )}
                  <ListItemText
                    primary={dataPassword.title}
                    secondary={dataPassword.username}
                  />
                </ListItem>
              ))}
            </List>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              type="submit"
              disabled={isLoading}
              onClick={async () => await sendDataToBackend(dataPasswords)}
            >
              Charger les mots de passe
              {isLoading && <CircularProgress color="secondary" />}
            </Button>
          </Grid>
        </>
      )}
    </Grid>
  );
};

const BitwardenMessage = () => {
  return (
    <>
      <Typography>
        Rendez-vous dans le gestionnaire de mots de passe de Bitwarden en
        cliquant sur{" "}
        <Link
          href="https://vault.bitwarden.com/#/tools/export"
          color="inherit"
          target="_blank"
          rel="noopener"
        >
          ce lien
        </Link>
        .
      </Typography>
      <Typography>Cliquez sur "OUTILS" puis "Exporter le coffre".</Typography>
      <Typography>Sélectionnez le format de fichier : .csv.</Typography>
      <Typography gutterBottom>
        Vous pourrez ensuite charger ce fichier ci-dessous pour lancer l'import
        dans Angelia.
      </Typography>
      <Typography>
        <strong>Attention :</strong> une fois l'import effectué, n'oubliez pas
        de supprimer complétement le fichier (puis de vider la corbeille) afin
        de protéger vos mots de passe.
      </Typography>
    </>
  );
};

const ChromeMessage = () => {
  return (
    <>
      <Typography>
        Rendez-vous dans le gestionnaire de mots de passe de Chrome en copiant
        cette adresse : "chrome://password-manager/settings".
      </Typography>
      <Typography>
        Puis téléchargez vos mots de passe en cliquant sur le bouton
        "Téléchargez le fichier" dans la catégorie "Exporter des mots de passe".
      </Typography>
      <Typography gutterBottom>
        Vous pourrez ensuite charger ce fichier ci-dessous pour lancer l'import
        dans Angelia.
      </Typography>
      <Typography>
        <strong>Attention :</strong> une fois l'import effectué, n'oubliez pas
        de supprimer complétement le fichier (puis de vider la corbeille) afin
        de protéger vos mots de passe.
      </Typography>
    </>
  );
};

export default UpdatePasswordView;
