import Papa, { ParseResult } from "papaparse";
import { useMemo, useState } from "react";
import { FileRejection } from "react-dropzone";

import { Browser } from "../";
import processBitwardenData, { BitwardenDataPassword } from "./processBitwardenData";
import processChromeData, { ChromeDataPassword } from "./processChromeData";
import { DataPassword } from "./useDataPasswords";

const useLoadFile = (
  browser: Browser,
  setDataPasswords: React.Dispatch<React.SetStateAction<DataPassword[]>>
) => {
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );
  const processResult =
    browser === "bitwarden"
      ? processBitwardenData
      : browser === "chrome"
      ? processChromeData
      : undefined;
  const isError = useMemo(() => errorMessage !== undefined, [errorMessage]);
  const onDrop = (acceptedFiles: File[], fileRejections: FileRejection[]) => {
    if (fileRejections.length > 0) {
      fileRejections.every((fileRejection) =>
        fileRejection.errors.every((error) => {
          if (error.code === "too-many-files") {
            setErrorMessage(
              "Merci de ne charger qu'un seul fichier à la fois."
            );
            return false;
          } else if (error.code === "file-invalid-type") {
            setErrorMessage("Seul des fichiers CSV sont autorisés.");
            return false;
          } else {
            setErrorMessage(error.message);
            return false;
          }
        })
      );
    } else {
      setErrorMessage(undefined);
    }
    if (acceptedFiles.length > 0 && processResult !== undefined) {
      Papa.parse(acceptedFiles[0], {
        header: true,
        skipEmptyLines: true,
        complete: (
          results: ParseResult<ChromeDataPassword | BitwardenDataPassword>
        ) => {
          setDataPasswords(processResult(results));
        },
      });
    }
  };
  return {
    onDrop,
    errorMessage,
    isError,
  };
};
export default useLoadFile;
