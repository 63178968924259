import axios from "axios";
import { useCallback, useContext } from "react";
import { useMutation } from "react-query";
import * as Yup from "yup";

import { hashValue } from "@bones-app/bones-cipher";
import { UserContext, UserInterface } from "@bones-app/react-app-coffre-fort";
import { SnackBarAlertInterface } from "@bones-app/react-snack-bar-alert";

const REQUIRED_LABEL = "Champs requis";
export const updateMfaSchema = Yup.object().shape({
  password: Yup.string().default("").required(REQUIRED_LABEL),
});
export interface UpdateMfaFormInterface {
  password: string;
}

interface UserMFAInterface extends UserInterface {
  otpUri: string;
}

const useOnSubmit = (
  addSnackBarAlert: (snackBarAlert: SnackBarAlertInterface) => void,
  setOtpUri: React.Dispatch<React.SetStateAction<string | undefined>>
) => {
  const {
    dispatch,
    state: { token },
  } = useContext(UserContext);

  const { isLoading, mutate: submitUpdatMfaForm } = useMutation(
    (formData: UpdateMfaFormInterface) =>
      patchUpdateMfaForm({
        formData,
        token,
      }),
    {
      onSuccess: (response) => {
        addSnackBarAlert({
          message: "Authentification à 2 facteurs activée.",
          severity: "success",
        });
        setOtpUri(response?.data.otpUri);
        dispatch({ type: "SET_HAS_MFA" });
      },
      onError: (error) => {
        if (axios.isAxiosError(error)) {
          addSnackBarAlert({
            message: "Problème lors de l'activation multi-facteur.",
            severity: "error",
          });
        } else {
          addSnackBarAlert({
            message: "Problème de connexion",
            severity: "error",
          });
        }
      },
    }
  );
  const onSubmit = useCallback(
    async (values: UpdateMfaFormInterface) => {
      if (!isLoading) {
        try {
          submitUpdatMfaForm(values);
        } catch (err) {
          console.error(err);
        }
      }
    },
    [submitUpdatMfaForm, isLoading]
  );
  return { onSubmit, isLoading };
};

const patchUpdateMfaForm = async ({
  formData,
  token,
}: {
  formData: UpdateMfaFormInterface;
  token?: string;
}) => {
  if (token) {
    const passwordHashed = await hashValue(formData.password);
    const response = await axios.patch<UserMFAInterface>(
      `${process.env.REACT_APP_BACKEND_URL}/user/otp`,
      {
        password: passwordHashed,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    return response;
  }
  return undefined;
};

export default useOnSubmit;
