import { ParseResult } from "papaparse";

export interface BitwardenDataPassword {
  folder?: string;
  favorite?: string;
  type?: string;
  name?: string;
  notes?: string;
  fields?: string;
  reprompt?: string;
  login_uri?: string;
  login_username?: string;
  login_password?: string;
  login_totp?: string;
}
const processBitwardenData = (results: ParseResult<BitwardenDataPassword>) => {
  return results.data.flatMap((result) => {
    if (!result.name || !result.login_password || !result.login_username) {
      return [];
    }
    return {
      title: result.name,
      description: result.notes,
      username: result.login_username,
      password: result.login_password,
      url: result.login_uri,
      isInstantShare: true,
      mustSendEmail: false,
      isLoading: false,
      isSuccess: false,
      isError: false,
    };
  });
};

export default processBitwardenData;
