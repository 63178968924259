import axios from "axios";
import { useCallback, useContext } from "react";
import { useMutation } from "react-query";
import * as Yup from "yup";

import {
  hashValue,
  updateCryptPrivateKeyPassword,
  updateSignPrivateKeyPassword,
} from "@bones-app/bones-cipher";
import { UserContext, UserInterface } from "@bones-app/react-app-coffre-fort";
import { SnackBarAlertInterface } from "@bones-app/react-snack-bar-alert";

import generateMnemonic from "./generateMnemonic";

const REQUIRED_LABEL = "Champs requis";
export const updateSeedSchema = Yup.object().shape({
  password: Yup.string().default("").required(REQUIRED_LABEL),
});
export interface UpdateSeedFormInterface {
  password: string;
}

const useOnSubmit = (
  addSnackBarAlert: (snackBarAlert: SnackBarAlertInterface) => void,
  setMnemonic: React.Dispatch<React.SetStateAction<string>>
) => {
  const {
    state: { cryptPrivateKey, signPrivateKey, token },
  } = useContext(UserContext);
  const mnemonic = generateMnemonic(128);

  const { isLoading, mutate: submitUpdatSeedForm } = useMutation(
    (formData: UpdateSeedFormInterface) =>
      patchUpdateSeedForm({
        formData,
        token,
        cryptPrivateKey,
        signPrivateKey,
        mnemonic,
      }),
    {
      onSuccess: () => {
        addSnackBarAlert({
          message: "Votre clé de récupération a été mise à jour.",
          severity: "success",
        });
        setMnemonic(mnemonic);
      },
      onError: (error) => {
        if (axios.isAxiosError(error)) {
          addSnackBarAlert({
            message:
              "Problème lors de la mise à jour de votre clé de récupération.",
            severity: "error",
          });
        } else {
          addSnackBarAlert({
            message: "Problème de connexion",
            severity: "error",
          });
        }
      },
    }
  );
  const onSubmit = useCallback(
    async (values: UpdateSeedFormInterface) => {
      if (!isLoading) {
        try {
          submitUpdatSeedForm(values);
        } catch (err) {
          console.error(err);
        }
      }
    },
    [submitUpdatSeedForm, isLoading]
  );
  return { onSubmit, isLoading };
};

const patchUpdateSeedForm = async ({
  formData,
  token,
  cryptPrivateKey,
  signPrivateKey,
  mnemonic,
}: {
  formData: UpdateSeedFormInterface;
  token?: string;
  cryptPrivateKey?: string;
  signPrivateKey?: string;
  mnemonic: string;
}) => {
  if (token && cryptPrivateKey && signPrivateKey) {
    const newSignPrivateKeyCrypted = await updateSignPrivateKeyPassword(
      signPrivateKey,
      mnemonic
    );
    const newCryptPrivateKeyCrypted = await updateCryptPrivateKeyPassword(
      cryptPrivateKey,
      mnemonic
    );
    const mnemonicHashed = await hashValue(mnemonic);
    const passwordHashed = await hashValue(formData.password);
    const response = await axios.patch<UserInterface>(
      `${process.env.REACT_APP_BACKEND_URL}/user/seed`,
      {
        password: passwordHashed,
        seed: mnemonicHashed,
        signPrivateKeySeeded: newSignPrivateKeyCrypted,
        cryptPrivateKeySeeded: newCryptPrivateKeyCrypted,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    return response;
  }
  return undefined;
};

export default useOnSubmit;
