import { useCallback, useContext, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { UserContext } from "@bones-app/react-app-coffre-fort";

const useMenu = () => {
  const [anchorEl, setAnchorEl] = useState<undefined | HTMLElement>(undefined);
  const openMenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);
  const closeMenu = useCallback(() => {
    setAnchorEl(undefined);
  }, []);
  return { anchorEl, openMenu, closeMenu };
};

const useLogout = (closeMenu: () => void) => {
  const {
    dispatch,
    state: { firstName, lastName },
  } = useContext(UserContext);
  const handleLogout = useCallback(() => {
    dispatch({ type: "REMOVE_LOGGED_USER" });
    closeMenu();
  }, [dispatch, closeMenu]);
  const initials = useMemo(
    () =>
      !!firstName && !!lastName
        ? firstName.charAt(0) + lastName.charAt(0)
        : "U",
    [firstName, lastName]
  );
  return { handleLogout, initials };
};

const useUpdatePassword = (closeMenu: () => void) => {
  const [isUpdatingPassword, setIsUpdatingPassword] = useState(false);
  const handleUpdatePassword = useCallback(() => {
    setIsUpdatingPassword(true);
    closeMenu();
  }, [closeMenu]);
  return { isUpdatingPassword, setIsUpdatingPassword, handleUpdatePassword };
};

const useUpdateSeed = (closeMenu: () => void) => {
  const [isUpdatingSeed, setIsUpdatingSeed] = useState(false);
  const handleUpdateSeed = useCallback(() => {
    setIsUpdatingSeed(true);
    closeMenu();
  }, [closeMenu]);
  return { isUpdatingSeed, setIsUpdatingSeed, handleUpdateSeed };
};

const useUpdateMFA = (closeMenu: () => void) => {
  const [isUpdatingMFA, setIsUpdatingMFA] = useState(false);
  const handleUpdateMFA = useCallback(() => {
    setIsUpdatingMFA(true);
    closeMenu();
  }, [closeMenu]);
  return { isUpdatingMFA, setIsUpdatingMFA, handleUpdateMFA };
};

const useImportDataPassword = (closeMenu: () => void) => {
  const [isImportingDataPassword, setIsImportingDataPassword] = useState(false);
  const handleImportDataPassword = useCallback(() => {
    setIsImportingDataPassword(true);
    closeMenu();
  }, [closeMenu]);
  return {
    isImportingDataPassword,
    setIsImportingDataPassword,
    handleImportDataPassword,
  };
};

const useAdminDashboard = (closeMenu: () => void) => {
  const navigate = useNavigate();
  const handleAdminDashboard = useCallback(() => {
    navigate("/admin/dashboard");
    closeMenu();
  }, [closeMenu, navigate]);
  return { handleAdminDashboard };
};

export {
  useMenu,
  useLogout,
  useUpdatePassword,
  useUpdateSeed,
  useUpdateMFA,
  useImportDataPassword,
  useAdminDashboard,
};
