import { memo } from "react";

import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";

const DialogForm = ({
  title,
  description,
  formView,
  isLoading,
  isEditing,
  setIsEditing,
}: {
  title: string;
  description: string | JSX.Element;
  formView: JSX.Element;
  isLoading?: boolean;
  isEditing: boolean;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  if (!isEditing) {
    return <></>;
  }
  return (
    <Dialog open onClose={() => setIsEditing(false)}>
      <DialogTitle>
        {title}
        <IconButton
          aria-label="close"
          onClick={() => setIsEditing(false)}
          disabled={isLoading}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{description}</DialogContentText>
        {formView}
      </DialogContent>
    </Dialog>
  );
};

export default memo(DialogForm);
