import { useIsLogged } from "@bones-app/react-app-coffre-fort";
import { useSnackBarAlert } from "@bones-app/react-snack-bar-alert";

import RightNavView from "./view";

const RightNav = () => {
  const isLogged = useIsLogged();
  const { addSnackBarAlert, currentSnack, handleClose, open } =
    useSnackBarAlert();
  return (
    <RightNavView
      isLogged={isLogged}
      addSnackBarAlert={addSnackBarAlert}
      snackBarMessageProps={{
        snackBarMessage: currentSnack,
        open,
        handleClose,
      }}
    />
  );
};

export default RightNav;
