import { SnackBarAlertInterface } from "@bones-app/react-snack-bar-alert";
import DashboardIcon from "@mui/icons-material/Dashboard";
import LogoutIcon from "@mui/icons-material/Logout";
import PasswordIcon from "@mui/icons-material/Password";
import ShieldIcon from "@mui/icons-material/Shield";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import {
  Avatar,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";

import ImportDataPassword from "./ImportDataPassword";
import UpdateMfa from "./UpdateMFA";
import UpdatePassword from "./UpdatePassword";
import UpdateSeed from "./UpdateSeed";

const AccountMenuView = ({
  initials,
  hasMFA,
  isDonor,
  isOrganizationAdmin,
  anchorEl,
  openMenu,
  closeMenu,
  handleLogout,
  isUpdatingPassword,
  setIsUpdatingPassword,
  handleUpdatePassword,
  isUpdatingSeed,
  setIsUpdatingSeed,
  handleUpdateSeed,
  isUpdatingMFA,
  setIsUpdatingMFA,
  handleUpdateMFA,
  isImportingDataPassword,
  setIsImportingDataPassword,
  handleImportDataPassword,
  handleAdminDashboard,
  addSnackBarAlert,
}: {
  initials: string;
  hasMFA: boolean;
  isDonor: boolean;
  isOrganizationAdmin: boolean;
  anchorEl?: HTMLElement;
  openMenu: (event: React.MouseEvent<HTMLElement>) => void;
  closeMenu: () => void;
  handleLogout: () => void;
  isUpdatingPassword: boolean;
  setIsUpdatingPassword: React.Dispatch<React.SetStateAction<boolean>>;
  handleUpdatePassword: () => void;
  isUpdatingSeed: boolean;
  setIsUpdatingSeed: React.Dispatch<React.SetStateAction<boolean>>;
  handleUpdateSeed: () => void;
  isUpdatingMFA: boolean;
  setIsUpdatingMFA: React.Dispatch<React.SetStateAction<boolean>>;
  handleUpdateMFA: () => void;
  isImportingDataPassword: boolean;
  setIsImportingDataPassword: React.Dispatch<React.SetStateAction<boolean>>;
  handleImportDataPassword: () => void;
  handleAdminDashboard: () => void;
  addSnackBarAlert: (snackBarAlert: SnackBarAlertInterface) => void;
}) => {
  return (
    <>
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={closeMenu}
        onClick={closeMenu}
      >
        {isDonor && (
          <MenuItem onClick={handleImportDataPassword}>
            <ListItemIcon>
              <UploadFileIcon fontSize="small" color="secondary" />
            </ListItemIcon>
            Importer mes mots de passes
          </MenuItem>
        )}
        {isOrganizationAdmin && (
          <MenuItem onClick={handleAdminDashboard}>
            <ListItemIcon>
              <DashboardIcon fontSize="small" color="secondary" />
            </ListItemIcon>
            Dashboard admin
          </MenuItem>
        )}
        <MenuItem onClick={handleUpdateSeed}>
          <ListItemIcon>
            <ShieldIcon fontSize="small" color="secondary" />
          </ListItemIcon>
          Générer une clé de récupération
        </MenuItem>
        <MenuItem onClick={handleUpdatePassword}>
          <ListItemIcon>
            <PasswordIcon fontSize="small" color="secondary" />
          </ListItemIcon>
          Changer mon mot de passe
        </MenuItem>
        {!hasMFA && (
          <MenuItem onClick={handleUpdateMFA}>
            <ListItemIcon>
              <VpnKeyIcon fontSize="small" color="secondary" />
            </ListItemIcon>
            Activer la double authentification
          </MenuItem>
        )}
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" color="secondary" />
          </ListItemIcon>
          Se déconnecter
        </MenuItem>
      </Menu>
      <UpdatePassword
        {...{
          isEditing: isUpdatingPassword,
          setIsEditing: setIsUpdatingPassword,
          addSnackBarAlert,
        }}
      />
      <UpdateSeed
        {...{
          isEditing: isUpdatingSeed,
          setIsEditing: setIsUpdatingSeed,
          addSnackBarAlert,
        }}
      />
      <UpdateMfa
        {...{
          isEditing: isUpdatingMFA,
          setIsEditing: setIsUpdatingMFA,
          addSnackBarAlert,
        }}
      />
      <ImportDataPassword
        {...{
          isEditing: isImportingDataPassword,
          setIsEditing: setIsImportingDataPassword,
          addSnackBarAlert,
        }}
      />
      <Tooltip title="Mon compte">
        <IconButton onClick={openMenu} size="small" sx={{ ml: 2 }}>
          <Avatar sx={{ backgroundColor: "#18a4de" }}>{initials}</Avatar>
        </IconButton>
      </Tooltip>
    </>
  );
};

export default AccountMenuView;
