import { Link as RouterLink } from "react-router-dom";

import {
  SnackBarAlert,
  SnackBarAlertInterface,
} from "@bones-app/react-snack-bar-alert";
import LoginIcon from "@mui/icons-material/Login";
import { AlertColor, Box, IconButton, Tooltip } from "@mui/material";

import AccountMenu from "./AccountMenu";

const RightNavView = ({
  isLogged,
  snackBarMessageProps,
  addSnackBarAlert,
}: {
  isLogged: boolean;
  snackBarMessageProps: {
    snackBarMessage?: { message: string; severity: AlertColor };
    open: boolean;
    handleClose: (event: React.SyntheticEvent | Event, reason?: string) => void;
  };
  addSnackBarAlert: (snackBarAlert: SnackBarAlertInterface) => void;
}) => {
  return (
    <Box sx={{ flexGrow: 0 }}>
      {isLogged ? (
        <AccountMenu addSnackBarAlert={addSnackBarAlert} />
      ) : (
        <Tooltip title="Se connecter">
          <IconButton
            size="large"
            component={RouterLink}
            to="/login"
            color="inherit"
          >
            <LoginIcon />
          </IconButton>
        </Tooltip>
      )}
      <SnackBarAlert {...snackBarMessageProps} />
    </Box>
  );
};

export default RightNavView;
