import { useContext, useState } from "react";

import { UserContext } from "@bones-app/react-app-coffre-fort";
import { SnackBarAlertInterface } from "@bones-app/react-snack-bar-alert";

import useOnSubmit from "./container";
import UpdateMfaView from "./view";

const UpdateMfa = ({
  isEditing,
  setIsEditing,
  addSnackBarAlert,
}: {
  isEditing: boolean;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
  addSnackBarAlert: (snackBarAlert: SnackBarAlertInterface) => void;
}) => {
  const [optUri, setOtpUri] = useState<string | undefined>(undefined);
  const {
    state: { hasMFA },
  } = useContext(UserContext);
  const { onSubmit, isLoading } = useOnSubmit(addSnackBarAlert, setOtpUri);
  return (
    <UpdateMfaView
      {...{ optUri, hasMFA, onSubmit, isLoading, isEditing, setIsEditing }}
    />
  );
};

export default UpdateMfa;
