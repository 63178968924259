import { SnackBarAlertInterface } from "@bones-app/react-snack-bar-alert";

import useOnSubmit from "./container";
import UpdatePasswordView from "./view";

const UpdatePassword = ({
  isEditing,
  setIsEditing,
  addSnackBarAlert,
}: {
  isEditing: boolean;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
  addSnackBarAlert: (snackBarAlert: SnackBarAlertInterface) => void;
}) => {
  const { onSubmit, isLoading } = useOnSubmit(addSnackBarAlert, setIsEditing);
  return (
    <UpdatePasswordView {...{ onSubmit, isLoading, isEditing, setIsEditing }} />
  );
};

export default UpdatePassword;
