import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";

import { Button, CircularProgress, Grid } from "@mui/material";

import DialogForm from "../../../../components/DialogForm";
import { UpdatePasswordFormInterface, updatePasswordSchema } from "./container";

const UpdatePasswordView = ({
  onSubmit,
  isLoading,
  isEditing,
  setIsEditing,
}: {
  onSubmit: (values: UpdatePasswordFormInterface) => Promise<void>;
  isLoading: boolean;
  isEditing: boolean;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const formView = <UpdatePasswordFormView {...{ onSubmit, isLoading }} />;
  return (
    <DialogForm
      {...{
        title: "Modification du mot de passe",
        description:
          "Modifiez régulièrement votre mot de passe afin d'assurer la sécurité maximale pour votre jardin secret Angelia. Pensez à activer de nouveau la double authentification en cas de changement de mot de passe.",
        formView,
        isLoading,
        isEditing,
        setIsEditing,
      }}
    />
  );
};

const UpdatePasswordFormView = ({
  onSubmit,
  isLoading,
}: {
  onSubmit: (values: UpdatePasswordFormInterface) => Promise<void>;
  isLoading: boolean;
}) => {
  return (
    <Formik
      initialValues={{
        oldPassword: "",
        password: "",
        passwordConfirmation: "",
      }}
      validationSchema={updatePasswordSchema}
      onSubmit={onSubmit}
    >
      {() => (
        <Form>
          <Grid
            container
            direction="row"
            justifyContent="space-evenly"
            alignItems="stretch"
            spacing={3}
            sx={{ paddingX: 3, paddingY: 5 }}
          >
            <Grid item xs={12}>
              <Field
                component={TextField}
                label="Mot de passe actuel"
                name="oldPassword"
                type="password"
                autoComplete="password"
                required={
                  updatePasswordSchema.fields.oldPassword.spec.presence ===
                  "required"
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                component={TextField}
                label="Nouveau mot de passe"
                name="password"
                type="password"
                autoComplete="new-password"
                required={
                  updatePasswordSchema.fields.password.spec.presence ===
                  "required"
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                component={TextField}
                label="Confirmation du nouveau mot de passe"
                name="passwordConfirmation"
                type="password"
                autoComplete="new-password"
                required={
                  updatePasswordSchema.fields.passwordConfirmation.spec
                    .presence === "required"
                }
                fullWidth
              />
            </Grid>
            <Grid item>
              <Button variant="contained" type="submit" disabled={isLoading}>
                Modifier le mot de passe et mettre à jour les clés
                {isLoading && <CircularProgress color="secondary" />}
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};
export default UpdatePasswordView;
