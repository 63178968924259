import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";

import { Button, Chip, CircularProgress, Grid } from "@mui/material";

import DialogForm from "../../../../components/DialogForm";
import { UpdateSeedFormInterface, updateSeedSchema } from "./container";

const UpdateSeedView = ({
  mnemonic,
  onSubmit,
  isLoading,
  isEditing,
  setIsEditing,
}: {
  mnemonic: string;
  onSubmit: (values: UpdateSeedFormInterface) => Promise<void>;
  isLoading: boolean;
  isEditing: boolean;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const formView = (
    <UpdateSeedFormView {...{ mnemonic, onSubmit, isLoading }} />
  );
  return (
    <DialogForm
      {...{
        title: "Modification de la clé de récupération",
        description:
          "Notez précieusement la clé de récupération et conservez là en sécurité. C'est le seul moyen de pouvoir récuperer votre compte en cas d'oubli de votre mot de passe principal. La génération d'une nouvelle clé désactive automatiquement les précédentes.",
        formView,
        isLoading,
        isEditing,
        setIsEditing,
      }}
    />
  );
};

const UpdateSeedFormView = ({
  mnemonic,
  onSubmit,
  isLoading,
}: {
  mnemonic: string;
  onSubmit: (values: UpdateSeedFormInterface) => Promise<void>;
  isLoading: boolean;
}) => {
  return (
    <Formik
      initialValues={{
        password: "",
      }}
      validationSchema={updateSeedSchema}
      onSubmit={onSubmit}
    >
      {() => (
        <Form>
          <Grid
            container
            direction="row"
            justifyContent="space-evenly"
            alignItems="stretch"
            spacing={3}
            sx={{ paddingX: 3, paddingY: 5 }}
          >
            <Grid item xs={12}>
              <Field
                component={TextField}
                label="Mot de passe"
                name="password"
                type="password"
                autoComplete="password"
                required={
                  updateSeedSchema.fields.password.spec.presence === "required"
                }
                fullWidth
              />
            </Grid>
            <Grid item>
              <Button variant="contained" type="submit" disabled={isLoading}>
                Générer une nouvelle clé de récupération
                {isLoading && <CircularProgress color="secondary" />}
              </Button>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="stretch"
            spacing={1}
          >
            {mnemonic &&
              mnemonic.split(" ").map((word, index) => {
                return (
                  <Grid item key={word}>
                    <Chip
                      label={`${index + 1}. ${word}`}
                      color="primary"
                      variant="outlined"
                    />
                  </Grid>
                );
              })}
          </Grid>
        </Form>
      )}
    </Formik>
  );
};
export default UpdateSeedView;
