import { Buffer } from "buffer";

import { sha256 } from "@noble/hashes/sha256";
import { randomBytes } from "@noble/hashes/utils";

import { frenchWords } from "./generateMnemonicFrench";

const generateMnemonic = (strength: number) => {
  const entropy = getRng(strength / 8);
  const entropyBits = bytesToBinary(Array.from(entropy));
  const checksumBits = deriveChecksumBits(entropy);
  const bits = entropyBits + checksumBits;
  const chunks = bits.match(/(.{1,11})/g);
  const words = chunks?.map((binary) => {
    const index = parseInt(binary, 2);
    return frenchWords[index];
  });
  return words?.join(" ") ?? "";
};

const getRng = (size: number) => Buffer.from(randomBytes(size));
const bytesToBinary = (bytes: number[]) =>
  bytes.map((x) => lpad(x.toString(2), "0", 8)).join("");
const lpad = (str: string, padString: string, length: number) => {
  while (str.length < length) {
    str = padString + str;
  }
  return str;
};
const deriveChecksumBits = (entropyBuffer: Buffer) => {
  const ENT = entropyBuffer.length * 8;
  const CS = ENT / 32;
  const hash = sha256(Uint8Array.from(entropyBuffer));
  return bytesToBinary(Array.from(hash)).slice(0, CS);
};

export default generateMnemonic;
