import { useContext } from "react";

import { UserContext } from "@bones-app/react-app-coffre-fort";
import { SnackBarAlertInterface } from "@bones-app/react-snack-bar-alert";

import {
  useAdminDashboard,
  useImportDataPassword,
  useLogout,
  useMenu,
  useUpdateMFA,
  useUpdatePassword,
  useUpdateSeed,
} from "./container";
import AccountMenuView from "./view";

const AccountMenu = ({
  addSnackBarAlert,
}: {
  addSnackBarAlert: (snackBarAlert: SnackBarAlertInterface) => void;
}) => {
  const {
    state: { hasMFA, isDonor, isOrganizationAdmin },
  } = useContext(UserContext);
  const { anchorEl, openMenu, closeMenu } = useMenu();
  const { handleLogout, initials } = useLogout(closeMenu);
  const { isUpdatingPassword, setIsUpdatingPassword, handleUpdatePassword } =
    useUpdatePassword(closeMenu);
  const { isUpdatingSeed, setIsUpdatingSeed, handleUpdateSeed } =
    useUpdateSeed(closeMenu);
  const { isUpdatingMFA, setIsUpdatingMFA, handleUpdateMFA } =
    useUpdateMFA(closeMenu);
  const {
    isImportingDataPassword,
    setIsImportingDataPassword,
    handleImportDataPassword,
  } = useImportDataPassword(closeMenu);
  const { handleAdminDashboard } = useAdminDashboard(closeMenu);
  return (
    <AccountMenuView
      {...{
        initials,
        hasMFA,
        isDonor,
        isOrganizationAdmin,
        anchorEl,
        openMenu,
        closeMenu,
        handleLogout,
        isUpdatingPassword,
        setIsUpdatingPassword,
        handleUpdatePassword,
        isUpdatingSeed,
        setIsUpdatingSeed,
        handleUpdateSeed,
        isUpdatingMFA,
        setIsUpdatingMFA,
        handleUpdateMFA,
        isImportingDataPassword,
        setIsImportingDataPassword,
        handleImportDataPassword,
        handleAdminDashboard,
        addSnackBarAlert,
      }}
    />
  );
};

export default AccountMenu;
