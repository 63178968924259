import { useState } from "react";

import { SnackBarAlertInterface } from "@bones-app/react-snack-bar-alert";

import useOnSubmit from "./container";
import UpdateSeedView from "./view";

const UpdateSeed = ({
  isEditing,
  setIsEditing,
  addSnackBarAlert,
}: {
  isEditing: boolean;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
  addSnackBarAlert: (snackBarAlert: SnackBarAlertInterface) => void;
}) => {
  const [mnemonic, setMnemonic] = useState("");
  const { onSubmit, isLoading } = useOnSubmit(addSnackBarAlert, setMnemonic);
  return (
    <UpdateSeedView
      {...{ mnemonic, onSubmit, isLoading, isEditing, setIsEditing }}
    />
  );
};

export default UpdateSeed;
