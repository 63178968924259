import { Accept, FileRejection } from "react-dropzone";

import DropZoneMaterialUIView from "./view";

const DropZoneMaterialUI = ({
  onDrop,
  accept,
  maxSize,
  isError,
  errorMessage,
}: {
  onDrop: (acceptedFiles: File[], fileRejections: FileRejection[]) => void;
  accept?: Accept;
  maxSize?: number;
  isError: boolean;
  errorMessage?: string;
}) => {
  return (
    <DropZoneMaterialUIView
      {...{ onDrop, accept, maxSize, isError, errorMessage }}
    />
  );
};

export default DropZoneMaterialUI;
