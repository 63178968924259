import axios, { AxiosResponse } from "axios";
import { useCallback, useContext } from "react";
import {
  UseMutateAsyncFunction,
  useMutation,
  useQueryClient,
} from "react-query";

import {
  ampli,
  DataPasswordInterface,
  UserContext,
} from "@bones-app/react-app-coffre-fort";
import {
  RegisterDataPasswordFormInterface,
  useOnSubmitDataPassword,
} from "@bones-app/react-component-coffre-fort";
import { SnackBarAlertInterface } from "@bones-app/react-snack-bar-alert";

import { DataPassword } from "./useDataPasswords";

const useSendDataToBackend = (
  addSnackBarAlert: (snackBarAlert: SnackBarAlertInterface) => void,
  updateDataPasswordElement: ({
    indexToUpdate,
    isLoading,
    isSuccess,
    isError,
  }: {
    indexToUpdate: number;
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
  }) => void
) => {
  const queryClient = useQueryClient();
  const {
    state: { token },
  } = useContext(UserContext);
  const { mutateAsync: submitSendOneDataToBackend } = useOnSubmitDataPassword({
    addSnackBarAlert,
    needSaveOnTezos: false,
    ampli,
  });
  const { mutate: saveOnTezos } = useMutation(
    (data: RegisterDataPasswordsPatch) => patchDataPasswords(data, token),
    {
      onError: () => {
        addSnackBarAlert({
          message: "Problème lors de l'enregistrement sur la blockchain",
          severity: "error",
        });
      },
      retry: 10,
      retryDelay: (attempt) => (2 ** attempt + 10 * attempt) * 1000,
    }
  );
  const { isLoading, mutate: submitSendDataToBackend } = useMutation(
    (dataPasswords: DataPassword[]) =>
      postDataPassword({
        dataPasswords,
        updateDataPasswordElement,
        submitSendOneDataToBackend,
      }),
    {
      onSuccess: (dataIds) => {
        saveOnTezos({ dataList: dataIds });
        queryClient.invalidateQueries({ queryKey: ["dataPassword", "sent"] });
        addSnackBarAlert({
          message: "Vos mots de passe ont été importés.",
          severity: "success",
        });
      },
      onError: (error) => {
        if (axios.isAxiosError(error)) {
          addSnackBarAlert({
            message: "Problème lors de l'import des mots de passe.",
            severity: "error",
          });
        } else {
          addSnackBarAlert({
            message: "Problème de connexion",
            severity: "error",
          });
        }
      },
    }
  );
  const sendDataToBackend = useCallback(
    async (values: DataPassword[]) => {
      if (!isLoading) {
        try {
          submitSendDataToBackend(values);
        } catch (err) {
          console.error(err);
        }
      }
    },
    [submitSendDataToBackend, isLoading]
  );
  return { sendDataToBackend, isLoading };
};

const postDataPassword = async ({
  dataPasswords,
  updateDataPasswordElement,
  submitSendOneDataToBackend,
}: {
  dataPasswords: DataPassword[];
  updateDataPasswordElement: ({
    indexToUpdate,
    isLoading,
    isSuccess,
    isError,
  }: {
    indexToUpdate: number;
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
  }) => void;
  submitSendOneDataToBackend: UseMutateAsyncFunction<
    AxiosResponse<DataPasswordInterface, any>,
    unknown,
    RegisterDataPasswordFormInterface,
    unknown
  >;
}) => {
  const dataIds = [] as RegisterDataPasswordsPatch["dataList"];
  for (let index = 0; index < dataPasswords.length; index++) {
    updateDataPasswordElement({
      indexToUpdate: index,
      isLoading: true,
      isSuccess: false,
      isError: false,
    });
    try {
      const response = await submitSendOneDataToBackend({
        ...dataPasswords[index],
        beneficiaryChoosenList: [],
      });
      dataIds.push({
        dataId: response.data.dataId,
        passwordSigned: response.data.passwordSigned,
      });
      updateDataPasswordElement({
        indexToUpdate: index,
        isLoading: false,
        isSuccess: true,
        isError: false,
      });
    } catch (error) {
      console.error(error);
      updateDataPasswordElement({
        indexToUpdate: index,
        isLoading: false,
        isSuccess: false,
        isError: true,
      });
    }
  }
  return dataIds;
};

interface RegisterDataPasswordsPatch {
  dataList: {
    dataId: number;
    passwordSigned: string;
  }[];
}

const patchDataPasswords = async (
  data: RegisterDataPasswordsPatch,
  token?: string
) => {
  if (!token) {
    throw new TypeError("token is undefined");
  }
  const response = await _axiosPatchDataPassword({
    url: `${process.env.REACT_APP_BACKEND_URL}/data/passwords`,
    token,
    data,
  });
  return response;
};

const _axiosPatchDataPassword = async ({
  url,
  token,
  data,
}: {
  url: string;
  token: string;
  data: RegisterDataPasswordsPatch;
}) =>
  await axios.patch<DataPasswordInterface[]>(url, data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  });

export default useSendDataToBackend;
