import axios from "axios";
import { useCallback, useContext } from "react";
import { useMutation } from "react-query";
import * as Yup from "yup";

import {
  hashValue,
  updateCryptPrivateKeyPassword,
  updateSignPrivateKeyPassword,
} from "@bones-app/bones-cipher";
import {
  ampli,
  UserContext,
  UserInterface,
} from "@bones-app/react-app-coffre-fort";
import { SnackBarAlertInterface } from "@bones-app/react-snack-bar-alert";

const REQUIRED_LABEL = "Champs requis";
export const updatePasswordSchema = Yup.object().shape({
  oldPassword: Yup.string().default("").required(REQUIRED_LABEL),
  password: Yup.string()
    .default("")
    .min(8, "8 caractères minimum.")
    .required(REQUIRED_LABEL),
  passwordConfirmation: Yup.string()
    .default("")
    .test("equal", "Les mots de passe ne correspondent pas", function (v) {
      const ref = Yup.ref("password");
      return v === this.resolve(ref);
    })
    .required(REQUIRED_LABEL),
});
export interface UpdatePasswordFormInterface {
  oldPassword: string;
  password: string;
  passwordConfirmation: string;
}

const useOnSubmit = (
  addSnackBarAlert: (snackBarAlert: SnackBarAlertInterface) => void,
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const {
    state: { cryptPrivateKey, signPrivateKey, token, amplitudeUserId },
  } = useContext(UserContext);
  const { isLoading, mutate: submitUpdatePasswordForm } = useMutation(
    (formData: UpdatePasswordFormInterface) =>
      patchUpdatePasswordForm({
        formData,
        token,
        cryptPrivateKey,
        signPrivateKey,
      }),
    {
      onSuccess: () => {
        addSnackBarAlert({
          message: "Votre mot de passe et vos clés ont été mise à jour.",
          severity: "success",
        });
        setIsEditing(false);
        ampli.significativeAction({
          user_id: amplitudeUserId,
        });
        ampli.passwordUpdated({
          user_id: amplitudeUserId,
        });
      },
      onError: (error) => {
        if (axios.isAxiosError(error)) {
          addSnackBarAlert({
            message: "Problème lors de la mise à jour du mot de passe.",
            severity: "error",
          });
        } else {
          addSnackBarAlert({
            message: "Problème de connexion",
            severity: "error",
          });
        }
      },
    }
  );
  const onSubmit = useCallback(
    async (values: UpdatePasswordFormInterface) => {
      if (!isLoading) {
        try {
          submitUpdatePasswordForm(values);
        } catch (err) {
          console.error(err);
        }
      }
    },
    [submitUpdatePasswordForm, isLoading]
  );
  return { onSubmit, isLoading };
};

const patchUpdatePasswordForm = async ({
  formData,
  token,
  cryptPrivateKey,
  signPrivateKey,
}: {
  formData: UpdatePasswordFormInterface;
  token?: string;
  cryptPrivateKey?: string;
  signPrivateKey?: string;
}) => {
  if (token && cryptPrivateKey && signPrivateKey) {
    const newSignPrivateKeyCrypted = await updateSignPrivateKeyPassword(
      signPrivateKey,
      formData.password
    );
    const newCryptPrivateKeyCrypted = await updateCryptPrivateKeyPassword(
      cryptPrivateKey,
      formData.password
    );
    const oldPasswordHashed = await hashValue(formData.oldPassword);
    const passwordHashed = await hashValue(formData.password);
    const response = await axios.patch<UserInterface>(
      `${process.env.REACT_APP_BACKEND_URL}/user/password`,
      {
        oldPassword: oldPasswordHashed,
        password: passwordHashed,
        signPrivateKeyCrypted: newSignPrivateKeyCrypted,
        cryptPrivateKeyCrypted: newCryptPrivateKeyCrypted,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    return response;
  }
  return undefined;
};

export default useOnSubmit;
