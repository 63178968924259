import "./index.css";

import ReactDOM from "react-dom/client";

import { ampli, AppRoot } from "@bones-app/react-app-coffre-fort";

import MainApp from "./App/MainApp";

ampli.load({
  environment: process.env.REACT_APP_BACKEND_URL?.includes("production")
    ? "production"
    : "development",
  client: {
    configuration: {
      disableCookies: true,
    },
  },
});
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(<AppRoot MainApp={MainApp} storage={sessionStorage} />);
